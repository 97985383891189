import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Brokerage Account: What You Should know`}</h1>
    <Breadcrumbs text="Help" to="/help/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/getting-started/"
          }}>{`Getting Started with TuringTrader`}</a></li>
      </ul>
    </Aside>
    <p>{`To hold your portfolio's assets, you need a brokerage account. There are many brokers to choose from, and `}<em parentName="p">{`TuringTrader.com`}</em>{` does not have any special requirements.`}</p>
    <h2>{`Account Types`}</h2>
    <p>{`The account type is one of the first questions that come up when opening a new brokerage account. From a 10,000 ft perspective, there are two main types of accounts:`}</p>
    <ul>
      <li parentName="ul">{`Taxable account`}</li>
      <li parentName="ul">{`Retirement account`}</li>
    </ul>
    <p><em parentName="p">{`Taxable brokerage accounts`}</em>{`, sometimes called `}<em parentName="p">{`standard brokerage accounts`}</em>{`, are much like any bank account. Investors can deposit and withdraw funds without restrictions, and no special tax treatment applies. Any income earned through interest or dividend payments, or gains on investments sold, are subject to taxes in the year the money is received. However, capital gains tax is typically much lower than the regular income tax rate, provided investors held their assets longer than one year before selling them.`}</p>
    <p><em parentName="p">{`Retirement accounts`}</em>{` are meant to save for retirement. Depending on the type of retirement accounts, there may be contribution limits, penalties for early withdrawal, and once you retire, even minimum required distributions. More importantly, though, there are significant tax advantages. Contributions to `}<em parentName="p">{`401(k) accounts`}</em>{` and `}<em parentName="p">{`traditional IRAs`}</em>{` are made with pre-tax money, and withdrawals are taxed at the investor's income tax rate. Contributions to `}<em parentName="p">{`Roth IRAs`}</em>{` are made with after-tax money, but withdrawals are tax-free if the owner has had the account for more than five years.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8f96469076f7b8def942695894b8a4ae/da099/aditya-vyas-mHdATQY9fIU-unsplash.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAARoHvLrEj//EABoQAAMBAAMAAAAAAAAAAAAAAAABAhEDEkH/2gAIAQEAAQUC2pbrlJtnndmJn//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB0QAAICAQUAAAAAAAAAAAAAAAABITFBAnFykaH/2gAIAQEABj8CujPZOv0e6Q+ROD//xAAcEAEAAwACAwAAAAAAAAAAAAABABEhMUFRcdH/2gAIAQEAAT8hHETpG/E1c9hw5t90nQfDEn1wa1u1T//aAAwDAQACAAMAAAAQ19//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAEBAQACAgMAAAAAAAAAAAABESEAoUGBMZGx/9oACAEBAAE/EDDsB4oM9s9cg1BGXUL3v1ynxYNRBEz43iopCC+A3t4mgYXfBmtS1MtP3n//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: Opening a brokerage account",
            "title": "TuringTrader.com: Opening a brokerage account",
            "src": "/static/8f96469076f7b8def942695894b8a4ae/e5166/aditya-vyas-mHdATQY9fIU-unsplash.jpg",
            "srcSet": ["/static/8f96469076f7b8def942695894b8a4ae/f93b5/aditya-vyas-mHdATQY9fIU-unsplash.jpg 300w", "/static/8f96469076f7b8def942695894b8a4ae/b4294/aditya-vyas-mHdATQY9fIU-unsplash.jpg 600w", "/static/8f96469076f7b8def942695894b8a4ae/e5166/aditya-vyas-mHdATQY9fIU-unsplash.jpg 1200w", "/static/8f96469076f7b8def942695894b8a4ae/d9c39/aditya-vyas-mHdATQY9fIU-unsplash.jpg 1800w", "/static/8f96469076f7b8def942695894b8a4ae/df51d/aditya-vyas-mHdATQY9fIU-unsplash.jpg 2400w", "/static/8f96469076f7b8def942695894b8a4ae/da099/aditya-vyas-mHdATQY9fIU-unsplash.jpg 5081w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Settlement and Account Margin`}</h2>
    <p>{`Tactical asset allocation requires that investors rotate their assets regularly. This involves selling one asset and using the proceeds to buy another. Unfortunately, things are slightly more complicated than that. For typical securities transactions, settlement occurs two business days after the order was executed. This settlement is often referred to as `}<em parentName="p">{`T+2`}</em>{`.`}</p>
    <p>{`It is considered a `}<em parentName="p">{`settlement violation`}</em>{` to sell securities before they are paid for with fully settled funds. When rotating assets, investors will pay for the 'new' securities with the unsettled proceeds from selling their 'old' securities. This results in limitations when the 'new' securities can be sold.`}</p>
    <p>{`In a `}<em parentName="p">{`cash account`}</em>{`, securities can only be bought with available cash. When rotating assets, this implies that the sell orders have to be executed before any buy orders. As the portfolios on `}<em parentName="p">{`TuringTrader.com`}</em>{` are rebalanced while the markets are closed, your broker might not accept the buy orders.`}</p>
    <p>{`A `}<em parentName="p">{`margin account`}</em>{` allows investors to buy securities on margin, i.e., with money borrowed from the broker. When implementing tactical portfolios, we recommend opening a margin account. While you won't need that margin to borrow money, it will help you stay clear of settlement violations and restrictions placing your orders.`}</p>
    <h2>{`Trading Permissions`}</h2>
    <p>{`Most of `}<em parentName="p">{`TuringTrader`}</em>{`'s portfolios solely invest in individual stocks and ETFs. Consequently, they do not require trading permissions for anything but stocks. Our portfolios do not include individual bonds. Instead, we are using bond ETFs, which are considered stocks in this context. Further, our portfolios do not invest in mutual funds or currency/ forex.`}</p>
    <p>{`Some of our portfolios make use of `}<em parentName="p">{`complex or leveraged exchange-traded products`}</em>{` (CLPs), though. If any of our portfolios use CLPs, we mention that in the section about account and tax considerations. To invest in these portfolios, most brokerages require you to pass a basic knowledge test about CLPs before adding the necessary trading permissions.`}</p>
    <h2>{`Fees and Commissions`}</h2>
    <p>{`Brokerages are businesses run with the expectations of creating shareholder value. Historically, brokerages earned fees and commissions for every order placed on their platform.`}</p>
    <p>{`In the 2020s, many brokerages offer commission-free trading, meaning that there is no charge for placing trades. As brokerages still need to pay exchange fees and turn a profit, they need to find ways to fund these commission-free trades. As a result, they turn to other sources of income, including interest from margin lending, fees for upgraded services, rehypothecation, and payment for order flow. Investors should understand that these practices have implications on their trade executions and account risk profile.`}</p>
    <p>{`Typical commissions at a discount broker are about $0.015 per share and trade. All of the charts and metrics on `}<em parentName="p">{`TuringTrader.com`}</em>{` account for these commissions. Therefore, we recommend against making commissions and fees the primary criterion in choosing a brokerage.`}</p>
    <h2>{`List of Online Brokerages`}</h2>
    <p>{`Here is an unordered and incomplete list of brokerages you might want to consider:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.interactivebrokers.com/"
        }}>{`Interactive Brokers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.schwab.com/"
        }}>{`Charles Schwab`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.tdameritrade.com/"
        }}>{`TD Ameritrade`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://robinhood.com/"
        }}>{`Robin Hood`}</a></li>
    </ul>
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      